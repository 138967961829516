import { render, staticRenderFns } from "./depositExpenditure.vue?vue&type=template&id=3d18b275&scoped=true&"
import script from "./depositExpenditure.vue?vue&type=script&lang=js&"
export * from "./depositExpenditure.vue?vue&type=script&lang=js&"
import style0 from "./depositExpenditure.vue?vue&type=style&index=0&id=3d18b275&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d18b275",
  null
  
)

export default component.exports